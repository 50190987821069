<template>
  <div class="invoice-management">
    <!-- Filter buttons -->
    <div class="invoice-filter mb-4">
      <div class="button-group">
        <button
          :class="['filter-btn', 'all-btn', { active: currentFilter === 'all' }]"
          @click="filterInvoices('all')"
        >
          Tous les factures
        </button>
        <button
          :class="['filter-btn', 'paid-btn', { active: currentFilter === 'paid' }]"
          @click="filterInvoices('paid')"
        >
          Factures Payées
        </button>
        <button
          :class="['filter-btn', 'unpaid-btn', { active: currentFilter === 'unpaid' }]"
          @click="filterInvoices('unpaid')"
        >
          Factures Non Payées
        </button>
      </div>
    </div>

    <!-- Invoice Table -->
    <b-table
      :items="filteredInvoices"
      :fields="tableFields"
      :current-page="currentPage"
      :per-page="pageLength"
      :total-rows="count"
      striped
      hover
      responsive
      show-empty
      @filtered="onFiltered"
    >
      <template #cell(created_at)="data">
        {{ formatDateTime(data.item.created_at) }}
      </template>

      <template #cell(start_date)="data">
        {{ formatDate(data.item.start_date) }}
      </template>

      <template #cell(end_date)="data">
        {{ formatDate(data.item.end_date) }}
      </template>

      <template #cell(total_amount)="data">
        {{ formatCurrency(data.item.total_amount) }}
      </template>

      <template #cell(status)="data">
        <b-badge :variant="getStatusVariant(data.item.status)">
          {{ data.item.status_display }}
        </b-badge>
      </template>

      <template #cell(actions)="data">
        <div class="d-flex gap-2">
          <b-button
            size="sm"
            variant="info"
            @click="showOrderDetails(data.item)"
          >
            Voir détails
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            @click="showInvoice(data.item)"
          >
            Voir facture
          </b-button>
        </div>
      </template>
    </b-table>

    <!-- Pagination -->
    <b-pagination
      v-model="currentPage"
      :total-rows="count"
      :per-page="pageLength"
      align="center"
      class="my-4"
    />

    <!-- Order Details Modal -->
    <b-modal
      v-model="showModal"
      title="Détails de la facture"
      size="lg"
      hide-footer
    >
      <div class="invoice-details">
        <div class="invoice-header mb-4">
          <h5>Facture #{{ selectedOrder.id }}</h5>
          <p>Période: {{ formatDate(selectedOrder.start_date) }} - {{ formatDate(selectedOrder.end_date) }}</p>
          <p>Total à payer: {{ formatCurrency(selectedOrder.total_amount) }}</p>
        </div>

        <div class="order-list">
          <h6>Liste des commandes ({{ selectedOrder.number_of_orders }} colis)</h6>
          <b-table
            :items="selectedOrder.order_details || []"
            :fields="orderDetailsFields"
            striped
            small
          >
            <template #cell(delivered_at)="data">
              {{ formatDate(data.item.delivered_at) }}
            </template>
            <template #cell(price_ttc)="data">
              {{ formatCurrency(data.item.price_ttc) }}
            </template>
          </b-table>
        </div>
      </div>
    </b-modal>

    <!-- Invoice Modal -->
    <b-modal
      v-model="showInvoiceModal"
      title="Facture"
      size="lg"
      hide-footer
      class="invoice-modal"
    >
      <div class="invoice-container">
        <!-- Company Header -->
        <div class="company-header">
          <div class="company-info">
            <img
              src="@/assets/images/logo/logo.png"
              alt="Company Logo"
              class="logo"
            >
            <h3 style="font-size: large;" > Direx </h3>
            <div style="font-size: large;"> Dar chaabene el fehri </div>
            <div style="font-size: large;" >Phone: +216 24 201 201</div>
          </div>
          <div class="invoice-info">
            <h2 style="font-size: large;" >Facture #{{ selectedOrder.id }}</h2>
            <div style="font-size: large;" >Date de création de facture: {{ formatDate(selectedOrder.created_at) }}</div>
            <div style="font-size: large;" >Période: {{ formatDate(selectedOrder.start_date) }} - {{ formatDate(selectedOrder.end_date) }}</div>
          </div>
        </div>

        <!-- Client Info -->
        <div class="client-info mt-4">
          <h4 style="font-size: large;" >Facture A:</h4>
          <div style="font-size: large;" >B2C Delivery</div>
          <div style="font-size: large;" ><span class="label">Adresse:</span> Hammamet</div>
          <div style="font-size: large;" ><span class="label">Matricule Fiscale:</span> 1870704/A/P/M/000</div>
          <div style="font-size: large;" ><span class="label">Phone:</span> 55030005</div>
        </div>

        <!-- Orders Table -->
        <div class="orders-table mt-4">
          <b-table
            :items="selectedOrder.order_details || []"
            :fields="invoiceDetailsFields"
            striped
            small
          >
            <template #cell(price_ttc)="data">
              {{ formatCurrency(data.item.price_ttc) }}
            </template>
          </b-table>
        </div>

        <!-- Total Section -->
        <div class="total-section mt-4">
          <div class="total-row">
            <strong>Total à payer:</strong>
            <span>{{ formatCurrency(selectedOrder.total_amount) }}</span>
          </div>
        </div>

        <!-- Print Button -->
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable,
  BModal,
  BBadge,
  BButton,
  BPagination,
} from 'bootstrap-vue'
import axios from 'axios'

export default {
  name: 'InvoiceManagement',
  components: {
    BTable,
    BModal,
    BBadge,
    BButton,
    BPagination,
  },
  data() {
    return {
      currentPage: 1,
      pageLength: 10,
      count: 0,
      invoices: [],
      currentFilter: 'all',
      showModal: false,
      showInvoiceModal: false,
      selectedOrder: {},
      tableFields: [
        { key: 'id', label: 'N° Facture' },
        { key: 'start_date', label: 'Date Début' },
        { key: 'end_date', label: 'Date Fin' },
        { key: 'created_at', label: 'Crée le' },
        { key: 'number_of_orders', label: 'Nombre de colis' },
        { key: 'total_amount', label: 'Net A payer' },
        { key: 'status', label: 'Status' },
        { key: 'actions', label: 'Actions' },
      ],
      orderDetailsFields: [
        { key: 'id', label: 'BL' },
        { key: 'delivered_at', label: 'Date de livraison' },
        { key: 'price_ttc', label: 'Prix TTC' },
      ],
      invoiceDetailsFields: [
        { key: 'id', label: 'BL' },
        { key: 'price_ttc', label: 'PRICE TTC' },
      ],
    }
  },
  computed: {
    filteredInvoices() {
      if (this.currentFilter === 'all') return this.invoices
      return this.invoices.filter(invoice => invoice.status === this.currentFilter)
    },
  },
  created() {
    this.getInvoices()
  },
  methods: {
    async getInvoices() {
      try {
        const response = await axios.get('/api/invoices/invoice-providers/')
        this.invoices = response.data
        this.count = response.data.length
      } catch (error) {
        console.error('Error fetching invoices:', error)
      }
    },
    async filterInvoices(filter) {
      this.currentFilter = filter
      try {
        const url = filter === 'all'
          ? '/api/invoices/invoice-providers/'
          : `/api/invoices/invoice-providers/${filter}_invoices/`
        const response = await axios.get(url)
        this.invoices = response.data
        this.count = response.data.length
      } catch (error) {
        console.error('Error filtering invoices:', error)
      }
    },
    formatDate(dateString) {
      if (!dateString) return '-'
      return new Date(dateString).toLocaleDateString('fr-FR')
    },
    formatDateTime(dateTimeString) {
      if (!dateTimeString) return '-'
      return new Date(dateTimeString).toLocaleString('fr-FR')
    },
    formatCurrency(amount) {
      if (!amount) return '0 TND'
      return `${parseFloat(amount).toLocaleString('fr-FR', {
        minimumFractionDigits: 3,
        maximumFractionDigits: 3,
      })} TND`
    },
    getStatusVariant(status) {
      const variants = {
        unpaid: 'danger',
        paid: 'success',
        default: 'secondary',
      }
      return variants[status] || variants.default
    },
    showOrderDetails(order) {
      this.selectedOrder = order
      this.showModal = true
    },
    showInvoice(order) {
      this.selectedOrder = order
      this.showInvoiceModal = true
    },
    printInvoice() {
      window.print()
    },
    onFiltered(filteredItems) {
      this.count = filteredItems.length
    },
  },
}
</script>

<style scoped>
.invoice-management {
  padding: 1rem;
}

.button-group {
  display: flex;
  gap: 12px;
  margin-bottom: 1rem;
}

.filter-btn {
  padding: 10px 20px;
  border: 2px solid transparent;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
}

.all-btn {
  border-color: #3498db;
  color: #3498db;
}

.all-btn.active {
  background-color: #3498db;
  color: white;
}

.paid-btn {
  border-color: #2ecc71;
  color: #2ecc71;
}

.paid-btn.active {
  background-color: #2ecc71;
  color: white;
}

.unpaid-btn {
  border-color: #e74c3c;
  color: #e74c3c;
}

.unpaid-btn.active {
  background-color: #e74c3c;
  color: white;
}

.filter-btn:hover:not(.active) {
  background-color: rgba(0, 0, 0, 0.05);
}

.invoice-container {
  padding: 2rem;
}

.company-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid #eee;
}

.company-info h3 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.invoice-info h2 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.client-info {
  margin-bottom: 2rem;
}

.client-info h4 {
  color: #e74c3c;
  margin-bottom: 1rem;
}

.label {
  color: #e74c3c;
  font-weight: 500;
  margin-right: 0.5rem;
}

.total-section {
  margin-top: 2rem;
  padding-top: 1rem;
  border-top: 2px solid #eee;
}

.total-row {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  font-size: 1.2rem;
}

.print-section {
  display: flex;
  justify-content: center;
  margin-top: 2rem;
}

.gap-2 {
  gap: 0.5rem;
}

.invoice-details {
  padding: 1.5rem;
}

.invoice-header {
  border-bottom: 1px solid #dee2e6;
  padding-bottom: 1rem;
}

.invoice-header h5 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.order-list {
  margin-top: 1.5rem;
}

.order-list h6 {
  color: #2c3e50;
  margin-bottom: 1rem;
}

.logo {
  width: 170px;  /* Adjust this value as needed for normal display */
  height: auto;
  padding: 0.5rem;
}

</style>
